import { StepperDesignTokens } from '@primeng/themes/types/stepper';
import { stepperStyles } from './generated/styles';

export const STEPPER_TOKENS = {
  step: {
    gap: '0',
  },
  separator: {
    activeBackground: '{perspio.main.color}',
  },
  css: () => stepperStyles,
} as StepperDesignTokens;
