import { TabsDesignTokens } from '@primeng/themes/types/tabs';
import { tabsStyles } from './generated/styles';

export const TABS_TOKENS = {
  colorScheme: {
    light: {
      tab: {
        activeColor: '{perspio.main.color}',
        activeBackground: 'transparent',
        activeBorderColor: '{perspio.main.color}',
        fontWeight: 'normal',
        padding: '1rem',
        borderWidth: '0',
        background: 'transparent',
      },
      tabpanel: {
        padding: '1.75rem 0 1.5rem 0',
        background: 'transparent',
      },
      tablist: {
        borderWidth: '0 0 2px 0',
        background: 'transparent',
      },
    },
  },
  css: () => tabsStyles,
} as TabsDesignTokens;
