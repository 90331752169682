import { CardDesignTokens } from '@primeng/themes/types/card';

export const CARD_TOKENS = {
  shadow:
    'rgba(60, 64, 67, 0.15) 0px 1px 2px 0px, rgba(60, 64, 67, 0.05) 0px 1px 3px 1px;',
  borderRadius: '8px',
  title: {
    fontSize: '1rem',
  },
  body: {
    padding: '1rem',
  },
} as CardDesignTokens;
