import { HelperService } from '@app/shared/services/helper.service';
import { Component, OnDestroy, OnInit } from '@angular/core';

import { ProgressSpinnerComponent } from '../progress-spinner/progress-spinner.component';

@Component({
    selector: 'app-overlay-spinner',
    templateUrl: './overlay-spinner.component.html',
    styleUrls: ['./overlay-spinner.component.scss'],
    imports: [ProgressSpinnerComponent]
})
export class OverlaySpinnerComponent implements OnInit, OnDestroy {
  constructor(private readonly helperService: HelperService) {}

  isLoading = false;
  subs = [];

  ngOnInit(): void {
    this.subs.push(
      this.helperService?.isLoading?.subscribe((res) => {
        this.isLoading = res;
      })
    );
  }

  ngOnDestroy(): void {
    this.subs?.forEach((sub) => sub?.unsubscribe());
  }
}
