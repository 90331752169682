import { DefaultTheme } from '@app/shared/components/theme-picker/theme.config';

export const COLOUR_SCHEME = {
  light: {
    primary: {
      color: '{perspio.primary.color}',
      hoverColor: '{perspio.primary.color}',
    },
    highlight: {
      background: '{perspio.primary.color}',
      color: '#fff',
      selected: {
        background: '{perspio.primary.color}',
        color: '#fff',
      },
      focus: {
        background: '{perspio.primary.color}',
        color: '#fff',
      },
    },
    formField: {
      disabled: {
        background: '#f9f9f9',
      },
    },
    focusRing: {
      ...DefaultTheme.focusRing,
    },
  },
};
