import {
  STORE_PLATFORM_HEALTH_DATA_INGESTION,
  STORE_PLATFORM_HEALTH_DATA_INTEGRATION,
  STORE_PLATFORM_HEALTH_DATA_ENGINES,
  STORE_PLATFORM_HEALTH_OPTIONS,
  STORE_PLATFORM_HEALTH_MODE,
  STORE_PLATFORM_HEALTH_DATA_ACTION,
  STORE_CONNECTOR_WEBHOOKS,
  UPDATE_PLATFORM_HEALTH_CONFIG,
} from '../actions/action-types';

const initialState: PlatformHealthData = {
  ingestion: null,
  integration: null,
  engine: null,
  actions: null,
  webhooks: null,
  platformHealthOrderBy: 'total',
  platformHealthMode: '',
};

interface PlatformHealthData {
  ingestion: any;
  integration: any;
  engine: any;
  actions: any;
  webhooks: any;
  platformHealthOrderBy: any;
  platformHealthMode: any;
}

export function platformHealthReducer(
  state = initialState,
  action
): PlatformHealthData {
  switch (action.type) {
    case STORE_PLATFORM_HEALTH_DATA_INGESTION:
      return {
        ...state,
        ingestion: action.payload,
      };

    case STORE_PLATFORM_HEALTH_DATA_INTEGRATION:
      return {
        ...state,
        integration: action.payload,
      };

    case STORE_PLATFORM_HEALTH_DATA_ENGINES:
      return {
        ...state,
        engine: action.payload,
      };

    case STORE_PLATFORM_HEALTH_OPTIONS:
      return {
        ...state,
        platformHealthOrderBy: action.payload,
      };

    case STORE_PLATFORM_HEALTH_MODE:
      return {
        ...state,
        platformHealthMode: action.payload,
      };

    case STORE_PLATFORM_HEALTH_DATA_ACTION:
      return {
        ...state,
        actions: action.payload,
      };
    case STORE_CONNECTOR_WEBHOOKS:
      return {
        ...state,
        webhooks: action.payload,
      };

    case UPDATE_PLATFORM_HEALTH_CONFIG:
      const index1 = state[action.payload.connectorType]?.findIndex(
        (connector) => connector?.id === action?.payload?.id
      );
      state[action.payload.connectorType][index1].configurations =
        action.payload.configurations;
      return state;

    default: {
      return state;
    }
  }
}
