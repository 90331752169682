import { AutoCompleteDesignTokens } from '@primeng/themes/types/autocomplete';

export const AUTOCOMPLETE_TOKENS = {
  colorScheme: {
    light: {
      borderRadius: '4px',
      dropdown: {
        background: 'white',
      },
    },
  },
} as AutoCompleteDesignTokens;
