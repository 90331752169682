import { AccordionDesignTokens } from '@primeng/themes/types/accordion';
import { accordionStyles } from './generated/styles';

export const ACCORDION_TOKENS = {
  contentPadding: 0,
  headerPadding: '1rem',
  headerFontWeight: 400,
  headerFirstTopBorderRadius: 0,
  headerActiveColor: '{perspio.mainColor}',
  headerHoverBackground: '#e7eaed',
  contentBorderWidth: 0,
  headerColor: '#495057',
  colorScheme: {
    light: {
      header: {
        hoverBackground: '#e7eaed',
        activeBackground: '#c3cad1',
        activeHoverColor: '{perspio.mainColor}',
        activeHoverBackground: '#e7eaed',
      },
    },
  },
  css: () => accordionStyles,
} as AccordionDesignTokens;
