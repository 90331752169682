import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  NavigationEnd,
  NavigationStart,
  Router,
  RouterOutlet,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { DialogService } from 'primeng/dynamicdialog';
import { MenuItem } from 'primeng/api';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import * as Sentry from '@sentry/angular';
import {
  Subject,
  Subscription,
  concatMap,
  filter,
  finalize,
  map,
  of,
  takeUntil,
  takeWhile,
} from 'rxjs';

import { environment } from '../environments/environment';
import { AppState } from '@app/shared/store/appState';
import { AppHttpService } from './shared/services/app-http.service';
import { AuthService } from './shared/services/auth.service';
import { GoogleMapsApiServiceService } from './shared/services/google-maps-api-service.service';
import { LocalisationService } from './shared/services/localisation.service';
import { TelemetryService } from './shared/services/telemetry.service';
import { UploadService } from '@app/shared/services/upload.service';
import { AssetStoreService } from './shared/components/asset-common';
import { MainSideBarComponent } from './perspio/app-wrapper/main-side-bar/main-side-bar.component';
import { SessionIdleDialogComponent } from './shared/components/session-idle-dialog/session-idle-dialog.component';
import { OverlaySpinnerComponent } from './shared/components/overlay-spinner/overlay-spinner.component';
import { AppLoadingComponent } from './shared/components/app-loading/app-loading.component';
import { PrimaryMenu, AdditionalMenu } from './shared/configs/app-menu.config';
import { Menu } from './shared/typings/menu';
import { provideGlobalGridOptions } from 'ag-grid-community';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  providers: [DialogService],
  imports: [
    MainSideBarComponent,
    RouterOutlet,
    SessionIdleDialogComponent,
    OverlaySpinnerComponent,
    AppLoadingComponent,
  ],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'PerspioApp';
  idleState = 'Not started.';
  showExpanded = false;
  timedOut = false;
  lastPing?: Date = null;
  isIframe = false;
  firstRun = false;
  subscription;
  subs: any = [];
  showSessionIdleModal = false;
  sessionIdleTime = 3600;
  favIcon: HTMLLinkElement = document.querySelector('#favIcon');
  roleDefinitionLoading = true;
  loginSuccess = false;
  roleDefinitionSelector = (state: AppState) => state.RoleDefinition;
  userDataSelector = (state: AppState) => state.UserData;
  private readonly _destroying$ = new Subject<void>();
  loginDisplay;
  tenant$;
  primaryMenu: Menu[];
  secondaryMenu: Menu[];
  additionalMenu: Menu[];
  allItems: MenuItem[];
  store$: Subscription;
  userstore$: Subscription;
  smallLogo = null;
  largeLogo = null;
  reportLogo = null;
  globalGridOptions = provideGlobalGridOptions;

  constructor(
    private readonly router: Router,
    private readonly msalBroadcastService: MsalBroadcastService,
    private readonly stateStore: Store<AppState>,
    private readonly titleService: Title,
    private readonly idle: Idle,
    private readonly keepalive: Keepalive,
    private readonly telemetryService: TelemetryService,
    public authService: AuthService,
    private readonly msalService: MsalService,
    private readonly appHttpService: AppHttpService,
    private readonly uploadService: UploadService,
    private readonly googleMapsApiServiceService: GoogleMapsApiServiceService,
    private readonly assetStoreService: AssetStoreService,
    private readonly cdr: ChangeDetectorRef,
    private readonly localisationService: LocalisationService,
    trace: Sentry.TraceService
  ) {
    if (environment.hasOwnProperty('title')) {
      this.titleService.setTitle(environment.title);
    }
    this.setGooglMapsAPIKey();
    this.handleSessionTimeout();

    this.subs.push(
      this.uploadService.refreshIcon$.subscribe(() => {
        this.getIcons();
      })
    );

    this.setAgGridDefaults();
  }

  setAgGridDefaults() {
    this.subs.push(
      this.localisationService.localisationPreferences$.subscribe(() => {
        this.globalGridOptions({
          excelStyles: [
            {
              id: 'dateType',
              dataType: 'DateTime',
              numberFormat: {
                format:
                  this.localisationService?.dates?.DATE_EXCEL_EXPORT_FORMAT,
              },
            },
          ],
        });
      })
    );
  }

  getMenuItems(): void {
    this.store$ = this.stateStore
      .select(this.roleDefinitionSelector)
      .subscribe((r) => {
        if (r?.data && PrimaryMenu && AdditionalMenu) {
          const enabledModules = r.data.modules.filter(
            (module) => module.enabled
          );

          const topLevelRouteIndex = 1;

          this.primaryMenu = PrimaryMenu.map((menu) => ({
            ...menu,
            disabled: !enabledModules.some((module) => module.id === menu.id),
            expanded: !!menu.subMenu?.find(
              (subMenu) =>
                `./${this.router.url.split('/')[topLevelRouteIndex]}` ===
                subMenu.route
            ),
          }));
          this.getSubmenuToggles(r.data.modules);

          this.additionalMenu = AdditionalMenu.map((menu) => ({
            ...menu,
            disabled: !enabledModules.some((module) => module.id === menu.id),
          }));

          this.allItems = [...this.primaryMenu, ...this.additionalMenu];
        } else {
          this.primaryMenu = [];
          this.additionalMenu = [];
          this.allItems = [];
        }
        this.cdr.detectChanges();
      });
    this.subs.push(this.store$);
  }

  onMenuToggle(): void {
    this.showExpanded = !this.showExpanded;
    this.assetStoreService?.mainSideMenuOpen.next(this.showExpanded);
  }

  getSubmenuToggles(modules): void {
    if (!modules) {
      return;
    }
    this.primaryMenu = [...this.primaryMenu]?.map((menu) => {
      if (menu?.subMenu?.length) {
        menu.subMenu = menu?.subMenu?.map((subMenu) => {
          subMenu.disabled = !modules?.find(
            (module) => module?.id === subMenu?.id
          )?.enabled;
          return subMenu;
        });
      }
      return menu;
    });
  }

  ngOnInit(): void {
    this.msalService.handleRedirectObservable().subscribe();

    this.getMenuItems();
    this.getIcons();

    this.subs.push(
      this.router.events.subscribe((event: any) => {
        if (event instanceof NavigationEnd) {
          const urlParts = event.urlAfterRedirects.split('/');
          const topLevelItemIndex = 1;
          const parentRoute = urlParts[topLevelItemIndex];

          this.primaryMenu.forEach((menuItem: Menu) => {
            if (menuItem.subMenu) {
              menuItem.expanded = menuItem.subMenu.some((subMenu) => {
                const included = parentRoute === subMenu.route.split('./')[1];
                return included;
              });
            } else {
              menuItem.expanded = menuItem.route.split('./')[1] === parentRoute;
            }
          });
          this.cdr.detectChanges();
        }
      })
    );

    this.isIframe = window !== window.parent && !window.opener;

    if (window.location.hostname.includes('share')) {
      this.roleDefinitionLoading = false;
      return;
    }

    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        if (this.setLoginDisplay()) {
          this.authService.currentUser =
            this.msalService.instance.getAllAccounts()[0];
          if (!this.authService.getUser()?.tid) {
            this.fetchTennantInfo();
          }
        } else {
          this.router.navigate(['/login']);
        }
      });

    this.clearAssetData();
    this.getRoleDefinition();

    this.subs.push(
      this.uploadService.refreshFavicon$.subscribe((s) => {
        this.getFavicon();
      })
    );
  }

  fetchTennantInfo(): void {
    this.tenant$ = this.appHttpService
      .fetchTenantInfo()
      .pipe(
        finalize(() => {
          this.authService.showLoader$.next(false);
        })
      )
      .subscribe((data) => {
        this.setUser(data);
        if (!this.authService.getUser().tid) {
          this.checkForTenants();
        }
      });
  }

  setUser(data): void {
    this.authService.setUser(
      this.authService.currentUser.idTokenClaims,
      data,
      this.authService.currentUser.localAccountId
    );
    this.authService.login();
  }

  getFavicon(): void {
    if (
      this.authService.user?.tenantName &&
      this.favIcon &&
      environment.hasOwnProperty('faviconName')
    ) {
      this.subs.push(
        this.uploadService.getTenantImages().subscribe((res: any[]) => {
          const favicon = res.find((x) => x.imgType === 'favicon')?.name;
          if (favicon) {
            this.uploadService
              .getImageUrl(favicon, 'tenant')
              .subscribe((imageUrl: string) => {
                if (imageUrl) {
                  this.favIcon.href = imageUrl;
                }
              });
          } else {
            this.favIcon.href = `assets/logos/${environment.faviconName}`;
          }
        })
      );
    }
  }

  getIcons(): void {
    let hasData = false;
    this.subs.push(
      this.authService.localStorageObserver$
        .asObservable()
        .pipe(
          takeWhile(() => !hasData),
          concatMap((data) => {
            if (data?.tid) {
              return this.uploadService
                .getTenantImages()
                .pipe(
                  map((res: any[]) =>
                    res.filter(
                      (i) =>
                        i.imgType === 'bgSmall' ||
                        i.imgType === 'bgLarge' ||
                        i.imgType === 'favicon'
                    )
                  )
                );
            } else {
              return of(null);
            }
          })
        )
        .subscribe((images) => {
          if (images && this.authService.user?.tenantName) {
            const { smallLogo, largeLogo, reportLogo, favicon } = {
              smallLogo: images.find((i) => i.imgType === 'bgSmall')?.name,
              largeLogo: images.find((i) => i.imgType === 'bgLarge')?.name,
              reportLogo: images.find((i) => i.imgType === 'reportLogo')?.name,
              favicon: images.find((i) => i.imgType === 'favicon')?.name,
            };

            this.smallLogo = smallLogo;
            this.largeLogo = largeLogo;
            this.reportLogo = reportLogo;

            this.uploadService.tenantImagesSubject.next({
              smallLogo: this.smallLogo,
              largeLogo: this.largeLogo,
              reportLogo: this.reportLogo,
            });

            hasData = true;
            this.cdr.detectChanges();

            // Check if favicon should be set
            if (this.favIcon && environment.hasOwnProperty('faviconName')) {
              if (favicon) {
                this.uploadService
                  .getImageUrl(favicon, 'tenant')
                  .subscribe((imageUrl: string) => {
                    if (imageUrl) {
                      this.favIcon.href = imageUrl;
                    }
                  });
              } else {
                this.favIcon.href = `assets/logos/${environment.faviconName}`;
              }
            }
          }
        })
    );
  }

  checkForTenants(): void {
    this.authService.checkForTenants();
  }

  setLoginDisplay(): boolean {
    const accounts = this.msalService.instance.getAllAccounts();
    const loggedIn = accounts?.length > 0;
    this.loginDisplay = loggedIn;
    return loggedIn;
  }

  getRoleDefinition(): void {
    this.subs.push(
      this.stateStore.select(this.roleDefinitionSelector).subscribe((r) => {
        this.roleDefinitionLoading = r?.isLoading;
      })
    );
  }

  clearAssetData(): void {
    this.subs.push(
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationStart) {
          if (
            event.url.indexOf('asset-tracker') !== -1 &&
            event.url.indexOf('asset-detail') === -1
          ) {
            this.stateStore.dispatch({
              type: 'STORE_ASSET_DATA_CLEAR',
            });
          }
        }
      })
    );
  }

  handleSessionTimeout(): void {
    this.idle.setIdle(this.sessionIdleTime);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.idle.onIdleEnd.subscribe(() => {
      this.reset();
    });
    this.idle.onIdleStart.subscribe(() => {
      this.showSessionIdleModal = true;
      this.telemetryService.stopConnection();
    });
    this.keepalive.interval(15);
    this.keepalive.onPing.subscribe(() => (this.lastPing = new Date()));
    this.reset();
  }

  reset(): void {
    this.idle.watch();
    this.idleState = 'Not started.';
    this.timedOut = false;
  }

  private setGooglMapsAPIKey() {
    this.googleMapsApiServiceService.load();
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
    this._destroying$.next(undefined);
    this._destroying$.complete();
    this.tenant$?.unsubscribe();
  }
}
