import { DatePickerDesignTokens } from '@primeng/themes/types/datepicker';
import { datepickerStyles } from './generated/styles';

export const DATEPICKER_TOKENS = {
  colorScheme: {
    light: {
      titleGap: 0,
      date: {
        selectedBackground: '{perspio.primary.color}',
        hoverBackground: '#e9ecef',
        height: '38px',
      },
      selectYear: {
        hoverBackground: 'transparent',
      },
      selectMonth: {
        hoverBackground: 'transparent',
      },
      panel: {
        borderRadius: '3px',
      },
    },
  },
  css: () => datepickerStyles,
} as DatePickerDesignTokens;
