import { ToggleSwitchDesignTokens } from '@primeng/themes/types/toggleswitch';
import { toggleswitchStyles } from './generated/styles';

export const TOGGLESWITCH_TOKENS = {
  root: {
    checkedHoverBackground: '{perspio.main.color} !important',
    checkedBackground: '{perspio.main.color} !important',
    disabledBackground: '{perspio.main.color} !important',
    borderColor: '#c2d1d9',
    checkedBorderColor: '#c2d1d9',
  },
  css: () => toggleswitchStyles,
} as ToggleSwitchDesignTokens;
