import { InputGroupDesignTokens } from '@primeng/themes/types/inputgroup';
import { inputgroupStyles } from './generated/styles';

export const INPUTGROUP_TOKENS = {
  colorScheme: {
    light: {
      addon: {
        background: '#e9ecef',
        color: '#6c757d',
        padding: '0.5rem 0.5rem',
        minWidth: '2.357rem',
        borderRadius: '3px',
      },
    },
  },
  css: () => inputgroupStyles,
} as InputGroupDesignTokens;
