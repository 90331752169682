import {
  enableProdMode,
  ErrorHandler,
  importProvidersFrom,
  inject,
  provideAppInitializer,
} from '@angular/core';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular';
import * as am5 from '@amcharts/amcharts5';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { InfiniteRowModelModule } from '@ag-grid-community/infinite-row-model';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import { SideBarModule } from '@ag-grid-enterprise/side-bar';
import { SparklinesModule } from '@ag-grid-enterprise/sparklines';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { Router } from '@angular/router';
import { ROUTER_PROVIDER } from './app/app-routing.config'; // Import the routerConfig symbol from the appropriate module
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { AuthInterceptor } from './app/shared/interceptor/auth.interceptor';
import { DataUpdateInterceptorService } from './app/shared/interceptor/data-update.interceptor';
import { MsalInterceptor } from '@azure/msal-angular';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { GoogleMapsModule } from '@angular/google-maps';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TooltipModule } from 'primeng/tooltip';
import { AppStoreModule } from './app/shared/store/store.module';
import { AppComponent } from './app/app.component';
import { MSAL_CONFIG_LIST } from '@app/msal.config';
import { CsvExportModule } from '@ag-grid-community/csv-export';
import { providePrimeNG } from 'primeng/config';
import { PERSPIO_PRESET_OVERRIDES } from 'styles/presets';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  InfiniteRowModelModule,
  MenuModule,
  CsvExportModule,
  ExcelExportModule,
  StatusBarModule,
  SideBarModule,
  SparklinesModule,
  MasterDetailModule,
  RowGroupingModule,
  ColumnsToolPanelModule,
]);

let envid;

//Add preconnect and dns-prefetch for the baseUri
const linkPreconnectElement = document.createElement('link');
const linkDnsPrefetchElement = document.createElement('link');
linkPreconnectElement.rel = 'preconnect';
linkDnsPrefetchElement.rel = 'dns-prefetch';
linkPreconnectElement.href = `${environment.baseUri}`;
linkDnsPrefetchElement.href = `${environment.baseUri}`;
document.head.appendChild(linkPreconnectElement);
document.head.appendChild(linkDnsPrefetchElement);

let user;
if (localStorage.currentUser) {
  user = JSON.parse(localStorage.currentUser);
}

if (environment.production) {
  enableProdMode();
  envid =
    'https://ba29110624aa4f54944235675a0754c5@o911970.ingest.sentry.io/5856371';
  Sentry.init({
    dsn: envid,
    release: environment.sentryTitle + '-' + environment.releaseId,
    environment: environment.title,
    autoSessionTracking: true,
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: 1.0,
    debug: false,
    initialScope: {
      user: { id: user?.id, email: user?.email, tenant: user?.tenantName },
    },
  });
}

am5.addLicense('AM5C304372588');

LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-065129}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{GINBOAT_ENTERPRISES_PTY_LTD}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Perspio}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Perspio}_need_to_be_licensed___{Perspio}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{11_October_2025}____[v3]_[01]_MTc2MDEzNzIwMDAwMA==bf7debadf28bf209590143b683824c63'
);

bootstrapApplication(AppComponent, {
  providers: [
    providePrimeNG({
      theme: {
        preset: PERSPIO_PRESET_OVERRIDES,
        options: {
          darkModeSelector: false || 'none',
        },
      },
    }),
    ROUTER_PROVIDER,
    importProvidersFrom(
      BrowserModule,
      NgIdleKeepaliveModule.forRoot(),
      GoogleMapsModule,
      FormsModule.withConfig({
        callSetDisabledState: false as any,
      }),
      ReactiveFormsModule.withConfig({
        callSetDisabledState: false as any,
      }),
      TooltipModule,
      AppStoreModule
    ),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    provideAppInitializer(() => {
      inject(Sentry.TraceService);
    }),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DataUpdateInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    ...MSAL_CONFIG_LIST,
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimationsAsync(),
  ],
}).catch((err) => console.error(err));
