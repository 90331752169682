import { ChipDesignTokens } from '@primeng/themes/types/chip';
import { chipsStyles } from './generated/styles';

export const CHIP_TOKEN = {
  paddingX: '0.5rem',
  paddingY: 0,
  gap: 0,
  colorScheme: {
    light: {
      color: '#fff',
      background: '{perspio.mainColor}',
      removeIcon: {
        color: '#fff',
      },
    },
  },
  css: () => chipsStyles,
} as ChipDesignTokens;
