// TODO: document what is a "tagFile" or get rid of this concept
const getTagFile = (file, plugin, provider) => {
  var _file$author, _file$author2;
  const tagFile = {
    id: file.id,
    source: plugin.id,
    name: file.name || file.id,
    type: file.mimeType,
    isRemote: true,
    data: file,
    preview: file.thumbnail || undefined,
    meta: {
      authorName: (_file$author = file.author) == null ? void 0 : _file$author.name,
      authorUrl: (_file$author2 = file.author) == null ? void 0 : _file$author2.url,
      // We need to do this `|| null` check, because null value
      // for .relDirPath is `undefined` and for .relativePath is `null`.
      // I do think we should just use `null` everywhere.
      relativePath: file.relDirPath || null,
      absolutePath: file.absDirPath
    },
    body: {
      fileId: file.id
    },
    remote: {
      companionUrl: plugin.opts.companionUrl,
      url: `${provider.fileUrl(file.requestPath)}`,
      body: {
        fileId: file.id
      },
      providerName: provider.name,
      provider: provider.provider,
      requestClientId: provider.provider
    }
  };
  return tagFile;
};
export default getTagFile;