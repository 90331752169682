import { MultiSelectDesignTokens } from '@primeng/themes/types/multiselect';
import { multiselectStyles } from './generated/styles';

export const MULTISELECT_TOKENS = {
  borderRadius: '4px',
  color: '{perspio.input.color}',
  padding: {
    y: '0.5rem !important',
  },
  colorScheme: {
    light: {
      dropdown: {
        borderRadius: '4px',
      },
    },
  },
  css: () => multiselectStyles,
} as MultiSelectDesignTokens;
