import { ProgressBarDesignTokens } from '@primeng/themes/types/progressbar';

export const PROGRESSBAR_TOKENS = {
  value: {
    background: '{perspio.primaryColor}',
  },
  label: {
    fontWeight: 'normal',
  },
  background: '{perspio.backgroundColor}',
} as ProgressBarDesignTokens;
