import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { MsalService } from '@azure/msal-angular';
import Uppy from '@uppy/core';
import Dashboard from '@uppy/dashboard';
import ImageEditor from '@uppy/image-editor';
import XHRUpload from '@uppy/xhr-upload';

import { environment } from 'environments/environment';
import {
  BehaviorSubject,
  catchError,
  from,
  map,
  Observable,
  Subject,
  tap,
} from 'rxjs';
import { AuthService } from './auth.service';
import { PluginTarget } from '@uppy/core/lib/UIPlugin';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  tenantImagesSubject = new BehaviorSubject<{
    smallLogo: string;
    largeLogo: string;
    reportLogo: string;
  }>(null);
  imageListSubject = new Subject<any[]>();
  imageList$: Observable<any> = this.imageListSubject.asObservable();
  bearerToken: string;
  addDocSubject = new Subject<void>();
  addDoc$: Observable<any> = this.addDocSubject.asObservable();
  addImageSubject = new Subject<void>();
  addImage$: Observable<any> = this.addImageSubject.asObservable();
  addLinkSubject = new Subject<void>();
  addLink$: Observable<any> = this.addLinkSubject.asObservable();
  refreshIconSubject = new Subject<void>();
  refreshIcon$: Observable<any> = this.refreshIconSubject.asObservable();
  clearModalsSubject = new Subject<void>();
  clearModals$: Observable<any> = this.clearModalsSubject.asObservable();
  refreshFaviconSubject = new Subject<void>();
  refreshFavicon$: Observable<any> = this.refreshFaviconSubject.asObservable();

  loadLatestImageSubject = new Subject<{ object: string; state: boolean }>();
  loadLatestImage$: Observable<{ object: string; state: boolean }> =
    this.loadLatestImageSubject.asObservable();

  constructor(
    private readonly http: HttpClient,
    private readonly authService: AuthService,
    private readonly msalService: MsalService
  ) {}

  initialiseImageUppy(
    uppy: Uppy,
    objectId: string,
    systemObjectType: 'dashboards' | 'assets' | 'tenant',
    metaFields = []
  ) {
    return from(
      this.msalService.instance.acquireTokenSilent({
        authority:
          'https://login.microsoftonline.com/7d548d61-6361-4a6e-85e6-509e2c05d05e/',
        account: this.msalService.instance.getAllAccounts()[0],
        scopes: environment.authScopes,
      })
    ).pipe(
      tap((response) => {
        this.bearerToken = `Bearer ${response.accessToken}`;
        uppy.use(Dashboard, {
          id: 'ImageDashboard',
          locale: {
            strings: {
              dropPasteFiles: 'Drop jpeg or png files here or %{browse}', // Modify as needed
            },
          } as any,
        });
        uppy.use(ImageEditor, {
          id: 'ImageEditor',
          target: Dashboard as unknown as PluginTarget<any, any>,
          actions: {
            revert: true,
            rotate: true,
            granularRotate: true,
            flip: true,
            zoomIn: true,
            zoomOut: true,
            cropSquare: true,
            cropWidescreen: true,
            cropWidescreenVertical: true,
          },
          quality: 0.8,
        });
        const id = this.authService.getTenantId();
        const subscription = environment.apiKey;

        const endpoint =
          systemObjectType === 'tenant'
            ? environment.baseUri + '/organisation/v3/logo'
            : environment.baseUri + `/assets/v3/${objectId}/images`;

        uppy.use(XHRUpload, {
          id: 'XHRUploadImage',
          headers: {
            authorization: this.bearerToken,
            tid: id,
            'ocp-apim-subscription-key': subscription,
          },
          allowedMetaFields:
            systemObjectType === 'tenant' ? ['imgType'] : metaFields,
          endpoint,
          fieldName: 'Image',
        });
      })
    );
  }

  initialiseDocUppy(uppy: Uppy, objectId: string) {
    return from(
      this.msalService.instance.acquireTokenSilent({
        authority:
          'https://login.microsoftonline.com/7d548d61-6361-4a6e-85e6-509e2c05d05e/',
        account: this.msalService.instance.getAllAccounts()[0],
        scopes: environment.authScopes,
      })
    ).pipe(
      tap((response) => {
        this.bearerToken = `Bearer ${response.accessToken}`;
        if (!uppy.getPlugin('Dashboard')) {
          uppy.use(Dashboard, {
            locale: {
              strings: {
                dropPasteFiles: 'Drop pdf files here or %{browse}', // Modify as needed
              },
            } as any,
          });
        }

        const id = this.authService.getTenantId();
        const subscription = environment.apiKey;

        const endpoint =
          environment.baseUri + `/assets/v3/${objectId}/documents`;

        uppy.use(XHRUpload, {
          headers: {
            authorization: this.bearerToken,
            tid: id,
            'ocp-apim-subscription-key': subscription,
          },
          allowedMetaFields: ['description'],
          endpoint,
        });
      })
    );
  }

  getDoc(systemObjectId: string, name: string) {
    //https://talk.perspio.dev/assets/v3/{systemObjId}/documents?name={documentName}
    return this.http.get(
      environment.baseUri +
        `/assets/v3/${systemObjectId}/documents?name=${name}`,
      { responseType: 'blob' as any }
    );
  }

  getDocs(systemObjectId: string) {
    return this.http.get(
      environment.baseUri + `/assets/v3/${systemObjectId}/documents`
    );
  }

  deleteDoc(systemObjectId: string, name: string) {
    return this.http.delete(
      environment.baseUri +
        `/assets/v3/${systemObjectId}/documents?name=${name}`,
      {
        body: [name],
      }
    );
  }

  updateFile(name: string, description: string) {
    return this.http.put(
      environment.baseUri +
        `/assets/v3/${name.split('/')[0]}/documents?name=${name}`,
      {
        name,
        description,
      }
    );
  }

  updateImage(name: string, description: string, isPreferred: boolean) {
    return this.http.put(
      environment.baseUri + `/assets/v3/${name.split('/')[0]}/images`,
      {
        name,
        description,
        isPreferred,
      }
    );
  }

  getImages(systemObjectId: string, systemObjectType: string) {
    return this.http.get(
      environment.baseUri + `/assets/v3/${systemObjectId}/images`
    );
  }

  getTenantImages() {
    return this.http.get(environment.baseUri + `/organisation/v3/logoImages`);
  }

  deleteImage(systemObjectId, name: string, systemObjectType: string) {
    return systemObjectType === 'tenant'
      ? this.http.delete(environment.baseUri + `/organisation/v3/logo`, {
          body: [name],
        })
      : this.http.delete(
          environment.baseUri +
            `/${systemObjectType}/v3/${systemObjectId}/images`,
          { body: [name] }
        );
  }

  getImageUrlSanitized(imageId: string, systemObjectType: string) {
    const url =
      systemObjectType === 'tenant'
        ? environment.baseUri +
          `/organisation/v3/logoImage${imageId.split('logo')[1]}`
        : environment.baseUri +
          `/assets/v3/${imageId.split('/')[0]}/images?name=${imageId}`;

    return this.http
      .get(url, {
        responseType: 'blob',
      })
      .pipe(
        map((response: any) => {
          const blob = new Blob([response], { type: response.type });
          return URL.createObjectURL(blob);
        }),
        catchError((error) => {
          console.log(error);
          return error;
        })
      );
  }

  getImageUrl(imageId: string, systemObjectType: string) {
    const url =
      systemObjectType === 'tenant'
        ? environment.baseUri +
          `/organisation/v3/logoImage${imageId.split('logo')[1]}`
        : environment.baseUri +
          `/assets/v3/${imageId.split('/')[0]}/images?name=${imageId}`;

    return this.http
      .get(url, {
        responseType: 'blob',
      })
      .pipe(
        map((response: any) => {
          const blob = new Blob([response], { type: response.type });
          const unsafeImg = URL.createObjectURL(blob);
          return unsafeImg;
        }),
        catchError((error) => {
          console.log(error);
          return error;
        })
      );
  }

  saveLink(systemObjectId, name: string, link: string) {
    return this.http.post(
      environment.baseUri + `/assets/v3/${systemObjectId}/links`,
      {
        linkName: name,
        link,
      }
    );
  }

  editLink(systemObjectId, name: string, link: string, documentId: string) {
    return this.http.put(
      environment.baseUri + `/assets/v3/${systemObjectId}/links/${documentId}`,
      {
        linkName: name,
        link,
      }
    );
  }

  deleteLink(systemObjectId, link: any) {
    return this.http.delete(
      environment.baseUri + `/assets/v3/${systemObjectId}/links`,
      { body: [link.rowKey] }
    );
  }

  getLinks(systemObjectId) {
    return this.http.get(
      environment.baseUri + `/assets/v3/${systemObjectId}/links`
    );
  }

  addDoc() {
    this.addDocSubject.next();
  }

  addImage() {
    this.addImageSubject.next();
  }

  addLink() {
    this.addLinkSubject.next();
  }
}

export function websiteUrlValidator(): ValidatorFn {
  const websiteRegex =
    /^(http:\/\/|https:\/\/)(?!.*\.\.)[A-Za-z0-9\-\.]+\.[A-Za-z]{2,}(\/\S*)?$/;

  return (control: AbstractControl): { [key: string]: any } | null => {
    const valid = websiteRegex.test(control.value);

    return valid ? null : { websiteUrl: { value: control.value } };
  };
}
