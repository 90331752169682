import { InputTextDesignTokens } from '@primeng/themes/types/inputtext';
import { inputtextStyles } from './generated/styles';

export const INPUTTEXT_TOKENS = {
  color: '#555',
  borderRadius: '3px',
  focusRing: {
    shadow: '0',
  },
  css: () => inputtextStyles,
} as InputTextDesignTokens;
