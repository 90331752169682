import { getSafeFileId } from '@uppy/utils/lib/generateFileID';
import getTagFile from './getTagFile.js';
const addFiles = (companionFiles, plugin, provider) => {
  const tagFiles = companionFiles.map(f => getTagFile(f, plugin, provider));
  const filesToAdd = [];
  const filesAlreadyAdded = [];
  tagFiles.forEach(tagFile => {
    if (plugin.uppy.checkIfFileAlreadyExists(getSafeFileId(tagFile, plugin.uppy.getID()))) {
      filesAlreadyAdded.push(tagFile);
    } else {
      filesToAdd.push(tagFile);
    }
  });
  if (filesToAdd.length > 0) {
    plugin.uppy.info(plugin.uppy.i18n('addedNumFiles', {
      numFiles: filesToAdd.length
    }));
  }
  if (filesAlreadyAdded.length > 0) {
    plugin.uppy.info(`Not adding ${filesAlreadyAdded.length} files because they already exist`);
  }
  plugin.uppy.addFiles(filesToAdd);
};
export default addFiles;